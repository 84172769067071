import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import {SimpleColDef} from '../../constants/propTypesDefinitions';

/**
 * SimpleTable is a component for displaying simple table data without header.
 * For every column attributes may be specified:
 *      a data keys which specify which values from data should be used in render method.
 *      a render method may be specified(function that returns react node). If render method is not specified a all data
 *      specified by data keys will be concatenated as string adn displayed.
 *      rights specifying from and to which rights should column be visible.
 *      class that is ised ass class for td wrapping content(produced by render method).
 *
 * Data structure is specified in SimpleColDef in /constants/propTypesDefinitions.
 *
 * Class may be specified for root of table(table tag) by className prop. This class will be added to basic styling
 * class of SimpleTable.
 *
 * @fero
 */

class SimpleTable extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        data: PropTypes.array.isRequired,
        colDefs: PropTypes.arrayOf(SimpleColDef.isRequired).isRequired,
        rowStyleGenerator: PropTypes.func,
    };

    static defaultProps = {
        className: "simple-table-default"
    };

    dataProps = (dataItem, dataKeys) => {
        if (dataKeys == null) 
        {
            return dataItem;
        }
        else if (Array.isArray(dataKeys)) 
        {
            let dataProps = {};
            dataKeys.forEach(key => {
                dataProps[key] = dataItem[key];
            });
            return dataProps;
        } 
        else 
        {
            return {[dataKeys]: dataItem[dataKeys]};
        }
    };

    cellElement = (dataProps, render, rowIdx) => {
        if (render != null) 
        {
            return render(dataProps, rowIdx);
        } 
        else 
        {
            let cellString = '';
            Object.keys(dataProps).forEach(key => {
                cellString += ((dataProps[key] != null ? dataProps[key] : '') + ' ');
            });
            return cellString;
        }
    };

    render() {
        const {colDefs, data, className, rowStyleGenerator, ...props} = this.props;
        return <table className={"simple-table " + className} {...props}>
            <tbody>
            {data.map((dataItem, rowIndex) => {
                const rowClass = rowStyleGenerator != null ? rowStyleGenerator(dataItem) : undefined;
                return <tr key={rowIndex} className={rowClass}>
                    {colDefs.map((colDef, index) => {
                        const render = colDef.render;
                        const classNameCell = colDef.class;
                        const dataKeys = colDef.dataKeys;
                        const dataProps = this.dataProps(dataItem, dataKeys);
                        return <RightsWrapper
                            key={rowIndex +  index}
                            from={colDef.rightsFrom}
                            to={colDef.rightsTo}
                        >
                            <td className={classNameCell}>
                                {this.cellElement(dataProps, render, rowIndex)}
                            </td>
                        </RightsWrapper>;
                    })}
                </tr>
            })}
            </tbody>
            <colgroup>
                {colDefs.map((colDef, i) => {
                    return <col key={i} style={colDef.style}/>
                })}
            </colgroup>
        </table>;
    }

}

export default SimpleTable;