import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {isQuotationSend} from '../../../lib/project';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationRemove} from '../../../backend/apiCalls';
import {Quotation, QuotationDetail, ButtonSize} from '../../../constants/propTypesDefinitions';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class RemoveQuotation extends React.PureComponent {
    static propTypes = {
        quotation: PropTypes.oneOfType([Quotation.isRequired, QuotationDetail.isRequired]).isRequired,
        size: ButtonSize,
        buttonContent: PropTypes.node,
        onFinishSuccessful: PropTypes.func,
        buttonClassName: PropTypes.string,
    };

    onFinishSuccessful = () => {
        const {onFinishSuccessful} = this.props;
        if (onFinishSuccessful != null) {
            onFinishSuccessful();
        }
    };

    render() {
        const {quotation, size, buttonContent, buttonClassName} = this.props;
        const disabled = isQuotationSend(quotation);
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip
                    title={disabled ? 
                        <Trans>Odoslanú cenovú ponuku nemožno odstrániť</Trans> :
                        (buttonContent != null ? null : <Trans>Odstrániť</Trans>)
                    }
                >
                    {
                        buttonContent == null ?
                            <Button
                                className={buttonClassName ? buttonClassName : ''}
                                size={size}
                                type="danger"
                                icon="close"
                                disabled={disabled}
                            />
                            :
                            <Button
                                className={buttonClassName ? buttonClassName : ''}
                                size={size}
                                type="danger"
                                disabled={disabled}
                            >
                                {buttonContent}
                            </Button>
                    }
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={this.onFinishSuccessful}
            title={<Trans>Odstránenie rozpracovanej cenovej ponuky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť rozpracovanú cenovú ponuku?</Trans>,
                createFetchQuotationRemove(quotation.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť cenovú ponuku.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveQuotation);