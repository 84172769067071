import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import Date from '../general/Date';

/**
 * @fero
 */

class QuotationAvailability extends React.PureComponent {
    static propTypes = {
        supplyDuration: PropTypes.number,
        availableAt: PropTypes.string,
    };

    render() {
        const {supplyDuration, availableAt} = this.props;

        return <div>
            {supplyDuration != null ?
                (supplyDuration == 0 ? 
                    (availableAt == null ? <div><Trans>skladom</Trans></div> : null) :
                    <div>
                        {supplyDuration}
                        {' '}
                        <Trans>prac. dní</Trans>
                    </div>
                ) : null
            }
            {availableAt != null ? 
                <div>
                    <Trans>vo výrobe od</Trans>
                    {' '}
                    <Date dateString={availableAt}/>
                </div> : null
            }
        </div>;
    }
}

export default QuotationAvailability;