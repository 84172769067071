import React from 'react';
import Helmet from '../project/Helmet';
import QuotationParams from './quotations/QuotationParams';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import QuotationsTable from './quotations/QuotationsTable';
import {Quotation} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import {Trans, t} from '@lingui/macro';
import { QUERY_PARAMS } from '../../constants/navigation';

/**
 * @fero
 */

class QuotationsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.QUOTATIONS]: PropTypes.arrayOf(Quotation.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {[GLOBAL_DATA.QUOTATIONS]: quotations, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Cenové ponuky`}
            />
            <TableWithFiltersLayout
                title={<Trans>Cenové ponuky</Trans>}
                params={<QuotationParams/>}
                table={<QuotationsTable
                    reload={() => reload([GLOBAL_DATA.QUOTATIONS])}
                    quotations={quotations}
                />}
                scrollParam={QUERY_PARAMS.QUOTATION_SCROLL}
            />
        </React.Fragment>;
    }
}

export default withDataHOC([GLOBAL_DATA.QUOTATIONS, GLOBAL_DATA.RELOAD_DATA])(QuotationsPage);